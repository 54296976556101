import { Component } from 'preact'
import MenuItem from './menuItem'

const courseToProps = (course, { courses, props }) => {
  const { key, dates, authorGenitiveLastname } = course
  const isMultipleCourses = courses.length > 1
  const isHighlighted = course === courses[0]
  const translationKey = isMultipleCourses ? 'myCourseWithAuthor' : 'myCourse'
  const className = isHighlighted ? (props.className + ' is__highlighted') : props.className

  return {
    title: I18n.t(`userPopup.${translationKey}`, { dates, authorGenitiveLastname }),
    href: `/courses/${key}/classroom/`,
    className,
  }
}

export default class MyCourseMenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = { courses: null }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.fetchMyCourses()
        .then(res => this.setState({ courses: res }))
    }
  }

  fetchMyCourses() {
    return fetch('/api/courses/my/', { credentials: 'include' })
      .then(res => res.json())
      .catch(() => null)
  }

  render() {
    const { courses } = this.state
    const props = this.props
    const commonProps = Object.assign({}, props)
    const toProps = course => courseToProps(course, { courses, props })

    if (!courses || !courses.length) return null

    if (courses.length > 1) {
      return (
        <li className="myCourses">
          <ul>
            { courses.map(course => (<MenuItem { ...commonProps } { ...toProps(course) } />)) }
          </ul>
        </li>
      )
    }

    return (<MenuItem { ...commonProps } { ...toProps(courses[0]) } />)
  }
}
