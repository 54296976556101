const toVideoPlayerOptions = require('toVideoPlayerOptions')
const ZenVideo = require('./zenVideo')

$(document).on('appReady', () => {
  document
    .querySelectorAll('.zenVideo')
    .forEach(el => {
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      new ZenVideo(el, options)
    })
})
