// FIXME: that doesn't look like the proper name for this function
// since we have 'full' formats

const {
  BIRMAN_MONTHS,
  BIRMAN_IN_MONTHS,
  FULL_IN_MONTHS,
  FULL_MONTHS,
  SHORT_WEEK_DAYS,
  WEEK_DAYS,
} = require('./constants')

// FIXME: localize the rest
const birmanize = (dateString, options = {}) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = BIRMAN_MONTHS[date.getMonth()]
  const inMonth = BIRMAN_IN_MONTHS[date.getMonth()]
  const inFullMonth = FULL_IN_MONTHS[date.getMonth()]
  const fullMonth = FULL_MONTHS[date.getMonth()]
  const day = date.getDate()
  const weekDay = SHORT_WEEK_DAYS[date.getDay()]
  const weekDayFull = WEEK_DAYS[date.getDay()]
  const isTueOrWed = [2, 3].includes(date.getDay())
  const sincePreposition = isTueOrWed ? 'Со' : 'С'
  const tillPreposition = 'До'

  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)

  const params = { year, month, inMonth, inFullMonth, fullMonth, day, weekDay, weekDayFull, hours, minutes }
  const t = key => I18n.t(`dateFormats.${key}`, params)

  switch (options.format) {
    case 'weekDay':
      return weekDay
    case 'weekDayAndDate':
      return `${weekDay} ${day} ${month}`
    case 'weekDayLowerCaseAndDate':
      return `${weekDay.toLowerCase()} ${day} ${month}`
    case 'sinceWeekDayLowerCaseAndDate':
      return `${sincePreposition} ${weekDay.toLowerCase()} ${day} ${month}`
    case 'tillWeekDayLowerCaseAndDate':
      return `${tillPreposition} ${weekDay.toLowerCase()} ${day} ${month}`
    case 'weekDaySmallcapsAndDate':
      return `<span class="smallcapitals">${weekDay}</span> ${day} ${month}`
    case 'weekDayFullAndDate':
      return `${weekDayFull} ${day} ${month}`
    case 'dateOnly':
      return t('dateOnly')
    case 'dateYearOnly':
      return t('dateYearOnly')
    case 'dateAndTime':
      return `${day} ${month} ${hours}:${minutes} мск`
    case 'dateAndTimeNotCurrentYear':
      return (
        (new Date()).getFullYear() == year ?
          `${day} ${month} ${hours}:${minutes} мск` :
          `${day} ${month} ${year} ${hours}:${minutes} мск`
      )
    case 'fullMonthDateOnly':
      return `${day} ${fullMonth}`
    case 'fullMonthDateNotCurrentYear':
      return (
        (new Date()).getFullYear() == year ?
          `${day} ${fullMonth}` :
          `${day} ${fullMonth} ${year}`
      )
    case 'fullMonthDateYearOnly':
      return `${day} ${fullMonth} ${year}`
    case 'fullMonthDateAndTime':
      return `${day} ${fullMonth} ${hours}:${minutes} мск`
    case 'fullMonthDateNotCurrentYearAndTime':
      return (
        (new Date()).getFullYear() == year ?
          `${day} ${fullMonth} ${hours}:${minutes} мск` :
          `${day} ${fullMonth} ${year} ${hours}:${minutes} мск`
      )
    case 'atTime':
      return `${day} ${month} в ${hours}:${minutes}`
    case 'month':
      return month
    case 'fullMonth':
      return fullMonth
    case 'inMonth':
      return `в ${inMonth}`
    case 'inFullMonthYear':
      return `в ${inFullMonth} ${year}`
    default:
      return `${day} ${month} ${hours}:${minutes} мск`
  }
}

module.exports = birmanize
