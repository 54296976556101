{
  const BANNER_CLASS = 'js__stickyLoanBanner'
  const WEBPAGE_BANNER_CLASS = 'has__stickyLoanBanner'

  class StickyLoanBanner {
    constructor($el) {
      this.$el = $el
      this.$document = $(document)
      this.$shaft = $('.stickyLoanBanner-shaft')
      this.$strut = $('<div class="stickyLoanBanner-strut"></div>')
      this.$loanSpacer = $('.js__loanSpacer')

      this.updateStickiness = this.updateStickiness.bind(this)

      this.setListeners()
      this.placeStrutBeforeHeader()
    }

    get hasLoanSpacer() {
      return this.$loanSpacer.length
    }

    get shaftStyle() {
      const height = this.hasLoanSpacer ? this.$loanSpacer.offset().top : $('body').height()

      return { height: `calc(${height}px - 100vh)` }
    }

    setListeners() {
      // HACK: use timeout to calculate getBoundingClientRect correctly on reload in Safary
      this.$document
        .on('appScroll appResize', this.updateStickiness)
        .on('appReady', () => setTimeout(this.updateStickiness, 100))
    }

    addWebpageClass() {
      $('.webpage').addClass(WEBPAGE_BANNER_CLASS)
    }

    placeStrutBeforeHeader() {
      this.$strut.css('height', this.$el.height() + 'px')
      this.$shaft.before(this.$strut)
      this.updateStickiness()
    }

    updateStickiness() {
      this.$shaft.css(this.shaftStyle)
    }
  }

  $(`.${BANNER_CLASS}`).each(function() {
    new StickyLoanBanner($(this))
  })
}
