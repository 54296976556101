const throttle = require('throttle')

const VideoPlayerState = require('lib/videoPlayerState')

const SAVE_PROGRESS_RATE_MS = 1000

const toIframe = src => `<iframe src="${src}" width="1280" height="720" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>`

class VkVideo {
  constructor(el, options = {}) {
    this.el = el
    this.id = el.getAttribute('data-vk-video-id')
    this.hash = el.getAttribute('data-vk-video-hash')
    this.startTime = el.getAttribute('data-vk-video-start')
    this.playerState = new VideoPlayerState({ id: `vkvi-${this.id}` })
    this.shouldSaveProgress = options.shouldSaveProgress

    let startTime = (this.shouldSaveProgress ? this.playerState.progress : null) || this.startTime

    this.appendIframeAndInitializePlayer({ startTime })
    this.setup({ startTime })
  }

  appendIframeAndInitializePlayer({ startTime }) {
    const [oid, id] = this.id.split('_')
    const { hash } = this
    let src = `https://vk.com/video_ext.php?oid=${oid}&id=${id}&js_api=1`

    if (hash) src = `${src}&hash=${hash}`
    if (startTime) src = `${src}&t=${startTime}s`

    const $iframe = $(toIframe(src))
    $(this.el).append($iframe)

    this.player = VK.VideoPlayer($iframe[0])
  }

  setup() {
    this.el.addEventListener('playNow', this.player.play.bind(this.player))

    if (this.shouldSaveProgress) {
      this.player.on('timeupdate', throttle(SAVE_PROGRESS_RATE_MS, this.saveProgress.bind(this)))
    }

    this.player.on('inited', () => this.player.unmute())
  }

  saveProgress({ time, duration }) {
    const seconds = parseInt(time, 10)

    this.playerState.saveProgress({ seconds, duration })
  }
}

module.exports = VkVideo
