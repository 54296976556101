import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarRequirements from './newPaybarRequirements'

class NewPaybarRequirementsAndMobileVersion extends Component {
  render() {
    return (
      <div className={ this.props.className }>
        <NewPaybarRequirements product={ this.props.product }/>
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    product: newPaybar.product,
    books: newPaybar.shelfBooks,
  }
}

export default connect(mapStateToProps)(NewPaybarRequirementsAndMobileVersion)
