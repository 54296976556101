import Cookies from 'js-cookie'
import { Component } from 'preact'

import { Cols } from '../cols'
import { Heading2, TextNode } from '../textNodes'
import EmailForm from '../emailForm'

export default class LeadMagnetBar extends Component {
  constructor(props) {
    super(props)

    this.state = { email: this.email }
  }

  get email() {
    return (window.application.user?.email || Cookies.get('email') || '').toLowerCase()
  }

  get buttonText() {
    return this.props.buttonText || I18n.t('leadMagnet.openLink')
  }

  get name() {
    return this.props.name || document.querySelector('h1')?.innerText
  }

  render(props) {
    return (
      <div className="leadMagnetWall-bar module is__boxed is__bleedBackgroundSides is__noMargins textBox" id="leadMagnetWallFooter">
        { props.name &&
          <Heading2 className="leadMagnetWall-header">{ this.name }</Heading2>
        }
        <Cols transposeOnMobile className="is__desktopDivisionProportions6to10">
          <div className="leadMagnetWall-description">
            <TextNode>{ props.text }</TextNode>
          </div>
          <div className="leadMagnetWall-emailForm">
            <EmailForm
              email={ this.state.email }
              buttonText={ this.buttonText }
              onSubmit={ props.onSubmit } />
          </div>
        </Cols>
      </div>
    )
  }
}
