import { Component } from 'preact'

import { Caption } from '../../textNodes'
import NewPaybarDevicesIcons from './newPaybarDevicesIcons'

export default class NewPaybarRequirements extends Component {
  get captionText() {
    return I18n.t('newPaybar.requirements.book')
  }

  render() {
    return (
      <div className="newPaybarRequirements">
        <NewPaybarDevicesIcons className="newPaybarRequirements-icon" />
        <div className="newPaybarRequirements-text">
          <Caption html={ this.captionText } />
        </div>
      </div>
    )
  }
}
