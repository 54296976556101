import { Provider } from 'preact-redux'

import { typograf } from 'lib/textHelper'
import getAccessLink from '../../assets/scripts/lib/getAccessLink'
import { createStore, getDefaultState } from './newPaybarStore'
import isSubscribed from './isSubscribed'
import NewPaybar from './newPaybar'
import paybarStubs from './newPaybarStubs'

const WrappedNewPaybar = (props) => {
  const store = createStore(props)
  return (<Provider store={ store }><NewPaybar /></Provider>)
}

const subscription = paybarStubs.install()
const { productDetails = {} } = window

const getShelfBooks = () => {
  const { product } = subscription.subscription
  const bookId = subscription.subscription.product_id.replace('book-', '')

  switch (product) {
    case 'book':
      return [{ key: bookId }]

    case 'bookshelf':
      return (window.productDetails || {}).books

    default:
      return []
  }
}

const getCollectureLectures = () => {
  const { product } = subscription.subscription

  switch (product) {
    case 'lecture':
      return [window.productDetails]

    case 'collecture':
      return (window.productDetails || {}).lectures

    default:
      return []
  }
}

const autodetectedProductTitle = () => typograf($($('.webpage').attr('description')).text())

const shouldInitializePaybar = (props) => {
  const isEmployee = props.subscription.subscription_type === 'employee'
  const { hasCompanyTabOnly } = props

  if (hasCompanyTabOnly && !isSubscribed(props)) return false
  if (hasCompanyTabOnly && isEmployee) return false

  return true
}

$('.js__newPaybar').each(function() {
  const product = subscription.subscription.product
  const productId = subscription.subscription.product_id
  const productTitle = window.NEW_PAYBAR_OPTIONS.productTitle ||
    subscription.subscription.productTitle ||
    productDetails.title ||
    autodetectedProductTitle()
  const goalPrefix = window.NEW_PAYBAR_OPTIONS.goalPrefix

  const props = {
    ...window.NEW_PAYBAR_OPTIONS,
    ...getDefaultState(subscription, productDetails),
    ...subscription,
    ...{
      product,
      productId,
      productTitle,
      paybarGoalVersion: window.application.user ? '' : 'NO-BUREAUSPHERE-',
      goalPrefix: goalPrefix || productId.toUpperCase(),
    },
    productDetails,
    shelfBooks: getShelfBooks(),
    lectures: getCollectureLectures(),
    accessLink: getAccessLink({ product, productId }),
  }

  if (shouldInitializePaybar(props)) $(this).reactify(WrappedNewPaybar, props)
})
