import { connect } from 'preact-redux'

import { Caption, TextNode } from '../../textNodes'
import { FormInput, FormTextarea } from '../../form'

import { formatPrice } from 'lib/textHelper'

import NewPaybarPersonalFieldsetBase from '../newPaybarPersonalFieldsetBase'
import NewPaybarPaymentSwitch from './newPaybarPaymentSwitch'
import NewPaybarEnrollmentSwitch from './newPaybarEnrollmentSwitch'
import NewPaybarParticipantTypeSwitch from '../newPaybarParticipantTypeSwitch'
import NewPaybarTelegramInput from '../newPaybarTelegramInput'
import NewPaybarPersonalFieldsetCompanySection from '../newPaybarPersonalFieldsetCompanySection'
import NewPaybarPersonalFieldsetGiftSection from '../newPaybarPersonalFieldsetGiftSection'

import { hasSelfSubscription, hasCompanySubscription } from '../helpers/companySubscriptionHelper'

import {
  addDirtyForm,
  removeDirtyForm,
  setFormValidity,
  setPaymentType,
  setEnrollment,
  setCompanyPaymentType,
  setParticipantType,
} from '../newPaybarActions'

import { getSchoolKeyFromSubscription } from './schoolHelpers'

const SELF_VALIDITY_MAPS = ['payerName', 'city', 'profession', 'tel']
const GIFT_VALIDITY_MAPS = ['payerName', 'presenteeName', 'presenteeEmail']
const COMPANY_VALIDITY_MAPS = ['companyTitle', 'companyEmail', 'companyCEO', 'companyTaxId']

class NewPaybarPersonalFieldset extends NewPaybarPersonalFieldsetBase {
  constructor(props) {
    super(props)

    this.validityStates = {
      payerName: !!this.props.userName,
      presenteeName: false,
      presenteeEmail: false,
      city: !!this.props.userCity,
      profession: !!this.props.userProfession,
      about: false,
      tel: false,
      telegram: false,
      portfolio: false,
    }

    this.validityMaps = {
      self: SELF_VALIDITY_MAPS,
      selfGrant: ['about', 'portfolio', ...SELF_VALIDITY_MAPS],
      gift: GIFT_VALIDITY_MAPS,
      company: ['companyElectronicDocFlowId', ...SELF_VALIDITY_MAPS, ...COMPANY_VALIDITY_MAPS],
      companyOther: [
        'presenteeCity', 'presenteeProfession', 'companyElectronicDocFlowId',
        ...GIFT_VALIDITY_MAPS, ...COMPANY_VALIDITY_MAPS,
      ],
      companyPaper: ['companyAddress', ...SELF_VALIDITY_MAPS, ...COMPANY_VALIDITY_MAPS],
      companyOtherPaper: [
        'presenteeCity', 'presenteeProfession', 'companyAddress',
        ...GIFT_VALIDITY_MAPS, ...COMPANY_VALIDITY_MAPS,
      ],
    }

    this.rememberedHiddenInputs = [
      'enrollment',
      'paymentType',
      'participantType',
      'companyPaymentType',
      'docFlowType',
    ]

    this.setGrantEnrollment = this.setGrantEnrollment.bind(this)
    this.setDefaultEnrollment = this.setDefaultEnrollment.bind(this)
    this.setOneTime = this.setOneTime.bind(this)
    this.setMonthly = this.setMonthly.bind(this)

    this.GrantEnrollmentFields = this.GrantEnrollmentFields.bind(this)
  }

  get isValid() {
    const { hasTerms, type, enrollment, participantType, docFlowType } = this.props

    if (hasTerms && !this.isAgreedToTerms) return false

    let validityMap = type

    if (type === 'self' && enrollment === 'grant') validityMap = 'selfGrant'

    if (type === 'company') {
      const participantSuffix = participantType === 'other' ? 'Other' : ''
      const docFlowSuffix = docFlowType === 'paper' ? 'Paper' : ''
      validityMap = `company${participantSuffix}${docFlowSuffix}`
    }

    return this.validityMaps[validityMap]
      .every(field => this.validityStates[field])
  }

  componentDidUpdate() {
    this.props.setFormValidity({ isValid: this.isValid })
    this.rememberedHiddenInputs.forEach(input => this.remember(input))
    this.saveAgreedToTerms()
  }

  setGrantEnrollment() {
    this.props.setEnrollment('grant')
  }

  setDefaultEnrollment() {
    this.props.setEnrollment('default')
  }

  setOneTime() {
    this.props.setPaymentType('oneTime')
  }

  setMonthly() {
    this.props.setPaymentType('monthly')
  }

  get enrollmentCaption() {
    if (this.props.enrollment === 'default') return I18n.t('newPaybar.school.defaultEnrollmentCaption')

    const schoolKey = getSchoolKeyFromSubscription(this.props.subscription)
    const contestLink = `/school/contest-${schoolKey}/`

    return I18n.t(`newPaybar.school.grantEnrollmentCaption.${schoolKey}`, { contestLink })
  }

  get paymentCaption() {
    const isOneTime = this.props.paymentType === 'oneTime'
    const prices = isOneTime ? this.props.prices : this.props.prices.monthly
    const price = formatPrice(prices.oneTime)
    const maxPrice = formatPrice(this.props.prices.monthly.total)

    return I18n.t(`newPaybar.school.${isOneTime ? 'oneTime' : 'monthly'}Caption`, { price, maxPrice })
  }

  get portfolioCaption() {
    const schoolKey = getSchoolKeyFromSubscription(this.props.subscription)

    return I18n.t(`newPaybar.school.portfolioCaption.${schoolKey}`)
  }

  get hasSelfSubscription() {
    const { subscription } = this.props

    return hasSelfSubscription(subscription) || hasCompanySubscription(subscription)
  }

  render() {
    const { GrantEnrollmentFields } = this
    const isGift = this.props.type === 'gift'
    const isSelf = this.props.type === 'self'
    const isCompany = this.props.type === 'company'
    const isSelfCompany = isCompany && (this.props.participantType === 'self')
    const isCompanyForOtherParticipant = isCompany &&
      (this.props.participantType === 'other' || this.hasSelfSubscription)

    return (
      <div className="newPaybarPersonalFieldset" ref={ this.bindEl }>
        { isSelf &&
          <div className="newPaybarPersonalFieldset-row is__switch has__caption">
            <NewPaybarEnrollmentSwitch
              enrollment={ this.props.enrollment }
              onSetGrantEnrollment={ this.setGrantEnrollment }
              onSetDefaultEnrollment={ this.setDefaultEnrollment } />
            <Caption html={ this.enrollmentCaption }></Caption>

            <input type="hidden" name="enrollment" value={ this.props.enrollment } />
          </div>
        }

        { !this.props.userName &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.name') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="payerName"
              type="text"
              autoComplete="name"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.payerName }
              ref={ ref => this.fieldRefs.payerName = ref } />
          </label>
        }

        { isCompany &&
          <div className="newPaybarPersonalFieldset-row is__switch">
            <NewPaybarParticipantTypeSwitch
              hasSelfSubscription={ this.hasSelfSubscription } />

            <input type="hidden" name="participantType" value={ this.props.participantType } />
          </div>
        }

        { (isSelf || isSelfCompany) &&
          <label className="newPaybarPersonalFieldset-row has__inlineCaption">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.tel') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormInput className="newPaybarPersonalFieldset-input"
                name="tel"
                type="tel"
                autoComplete="tel"
                required
                onInput={ this.onInput }
                onValidityChange={ this.onInputValidityChange }
                initialValue={ this.rememberedFields.tel || this.props.userTel }
                ref={ ref => this.fieldRefs.tel = ref } />
              <Caption html={ I18n.t('newPaybar.school.telCaption') }></Caption>
            </div>
          </label>
        }

        { (isSelf || isSelfCompany) &&
          <NewPaybarTelegramInput
            name="telegram"
            onInput={ this.onInput }
            onValidityChange={ this.onInputValidityChange }
            initialValue={ this.rememberedFields.telegram || this.props.userTelegram }
            ref={ ref => this.fieldRefs.telegram = ref } />
        }

        { (isSelf || isSelfCompany) && !this.props.userCity &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.city') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="city"
              key="city"
              type="text"
              autoComplete="city"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.city }
              ref={ ref => this.fieldRefs.city = ref } />
          </label>
        }

        { (isSelf || isSelfCompany) && !this.props.userProfession &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="profession"
              type="text"
              autoComplete="organization-title"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.profession }
              ref={ ref => this.fieldRefs.profession = ref } />
          </label>
        }

        { isSelf && this.props.enrollment === 'grant' &&
          <GrantEnrollmentFields/>
        }

        { isSelf &&
          <div className="newPaybarPersonalFieldset-row is__switch has__caption">
            <NewPaybarPaymentSwitch
              payment={ this.props.paymentType }
              onSetMonthly={ this.setMonthly }
              onSetOneTime={ this.setOneTime } />
            <Caption html={ this.paymentCaption }></Caption>

            <input type="hidden" name="paymentType" value={ this.props.paymentType } />
          </div>
        }

        { (isGift || isCompanyForOtherParticipant) &&
          <NewPaybarPersonalFieldsetGiftSection
            rememberedFields={ this.rememberedFields }
            fieldRefs={ this.fieldRefs }
            onInput={ this.onInput }
            onInputValidityChange={ this.onInputValidityChange }
            hasCaption={ isCompanyForOtherParticipant }
            hasHeading={ !isCompany }
            hasTelegram={ isCompanyForOtherParticipant }
            hasTel={ isCompanyForOtherParticipant }
            hasFullPresenteeName />
        }

        { isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.city') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="presenteeCity"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeCity }
              ref={ ref => this.fieldRefs.presenteeCity = ref } />
          </label>
        }

        { isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeProfession"
              key="presenteeProfession"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeProfession }
              ref={ ref => this.fieldRefs.presenteeProfession = ref } />
          </label>
        }

        { isCompany &&
          <NewPaybarPersonalFieldsetCompanySection
            rememberedFields={ this.rememberedFields }
            fieldRefs={ this.fieldRefs }
            companyPaymentType={ this.props.companyPaymentType }
            setCardCompanyPayment={ this.setCardCompanyPayment }
            setInvoiceCompanyPayment={ this.setInvoiceCompanyPayment }
            onInput={ this.onInput }
            onInputValidityChange={ this.onInputValidityChange } />
        }
      </div>
    )
  }

  GrantEnrollmentFields() {
    return (
      <div className="newPaybarPersonalFieldset-section">
        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.school.about') }
          </TextNode>
          <FormTextarea
            className="newPaybarPersonalFieldset-input"
            name="about"
            required
            onInput={ this.onInput }
            onValidityChange={ this.onInputValidityChange }
            initialValue={ this.rememberedFields.about }
          />
        </label>

        <label className="newPaybarPersonalFieldset-row has__caption">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.school.portfolio') }
          </TextNode>
          <div className="newPaybarPersonalFieldset-inputWithCaption">
            <FormInput className="newPaybarPersonalFieldset-input"
              name="portfolio"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.portfolio }
              ref={ ref => this.fieldRefs.portfolio = ref } />
            <Caption html={ this.portfolioCaption }></Caption>
          </div>
        </label>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setFormValidity,
  addDirtyForm,
  removeDirtyForm,
  setPaymentType,
  setEnrollment,
  setCompanyPaymentType,
  setParticipantType,
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    userName: newPaybar.userName,
    userCity: newPaybar.userCity,
    userProfession: newPaybar.userProfession,
    userTel: newPaybar.userTel,
    userTelegram: newPaybar.userTelegram,
    isVisible: newPaybar.isVisible,
    paymentType: newPaybar.paymentType,
    companyPaymentType: newPaybar.companyPaymentType,
    participantType: newPaybar.participantType,
    enrollment: newPaybar.enrollment,
    prices: newPaybar.prices,
    subscription: newPaybar.subscription,
    isAgreedToTerms: newPaybar.isAgreedToTerms,
    docFlowType: newPaybar.docFlowType,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPersonalFieldset)
