const showLeadMagnet = (leadMagnetId) => {
  const suffix = leadMagnetId ? `:${leadMagnetId}` : ''

  $(document).trigger(`leadMagnetShow${suffix}`)
}

$('.js__leadMagnet').each(function() {
  const $el = $(this)
  const options = JSON.parse($el.attr('data-options')) || {}
  const hasHrefOption = !!options.href
  const href = options.href || $el.find('[href]').first().attr('href')
  const leadMagnetId = options.id || ''

  $el.on('click', function(e) {
    const $clickedTarget = $(e.target)
    const $closestLink = $clickedTarget.closest('a')
    const $target = $closestLink.length ? $closestLink : $clickedTarget

    const isWholeMagnetClicked = hasHrefOption && !$el.find('a, button').length
    const isButtonClicked = $target.is('button')
    const isEmptyLinkClicked = $target.is('a') && !$target.attr('href')
    const isProtectedLinkClicked = $target.is('a') && $target.attr('href') === href

    if (isWholeMagnetClicked || isButtonClicked || isEmptyLinkClicked || isProtectedLinkClicked) {
      e.preventDefault()

      showLeadMagnet(leadMagnetId)
    }
  })
})
