const PLATFORMS = {
  'Сайт бюро': 'bu',
  'Главная страница сайта бюро': 'bui',
  'Страницы школы на сайте бюро': 'busc',
  Почта: 'm',
  Телеграм: 'tg',
  Твиттер: 'twi',
  Фейсбук: 'fb',
  Инстаграм: 'in',
  Ютуб: 'yt',
  ТикТок: 'tt',
  Вконтакте: 'vk',
  Яндекс: 'ya',
  Гугль: 'g',
  'glvrd.ru': 'gl',
  'maximilyahov.ru': 'il',
  'ilyabirman.ru': 'bi',
  'fff.works': 'fw',
  'sinelnikov.name': 'sn',
  'Яндекс.Практикум': 'yp',
  Суперджоб: 'sj',
  Палиндром: 'pl',
}

const POST_TYPES = {
  Совет: 's',
  Новость: 'n',
  Рассылка: 'ml',
  Напоминание: 'nt',
  Вторсырьё: 'r',
  'Рекламный пост': 'ad',
  Банер: 'b',
  Рекомендация: 'sg',
  Рекомендации: 'sg',
  'Партнёрский пост': 'p',
  Превью: 'prw',
  Апсел: 'ups',
  Кроссел: 'xs',
  Лидформа: 'lf',
  Евангелист: 'ev',
}

const PRODUCT_TYPES = {
  Новости: 'n',
  Проекты: 'p',
  Книги: 'b',
  'Подарочные сертификаты': 'bg',
  Школа: 'sc',
  Курсы: 'c',
  Лекции: 'l',
  Советы: 's',
  Бюро: 'bu',
  'Телеграм-канал': 'tg',
  'Фейсбук-группа': 'fb',
  'Вконтакте-группа': 'vk',
  'Твиттер-канал': 'tw',
  'Ютуб-канал': 'yt',
  Пиар: 'pr',
  Мультипродукт: 'multi',
}

const CURRENT_DATE_PLACEHOLDER = 'CURRENT_DATE'
const INDEX_PREVIEW_DEFAULTS = {
  platform: 'Главная страница сайта бюро',
  postType: 'Превью',
}

const zeroPadded = number => ('0' + number).slice(-2)

const toDateParam = (date) => {
  if (!date) return null

  date = new Date(date)

  return [
    zeroPadded(date.getDate()),
    zeroPadded(date.getMonth() + 1),
    date.getFullYear().toString().slice(-2),
  ].join('')
}

const toProductParam = product => product ? product.split('-').join('') : product

const utmSource = ({ platform, postType, productType, product, id, postDate, linkNumber }) => {
  platform = PLATFORMS[platform] || platform || 'bu'
  postType = POST_TYPES[postType] || postType
  productType = PRODUCT_TYPES[productType] || productType

  return [
    platform,
    postType,
    productType,
    toProductParam(product),
    id,
    postDate ? toDateParam(postDate) : CURRENT_DATE_PLACEHOLDER,
    linkNumber,
  ].filter(part => part).join('-')
}

module.exports = {
  utmSource,
  toDateParam,
  CURRENT_DATE_PLACEHOLDER,
  INDEX_PREVIEW_DEFAULTS,
}
