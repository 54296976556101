import { Image } from '../image'

const DEFAULT_IMAGE_SIZE = [1381, 777]

const SovietUnansweredImage = ({ sovietUrl, imageUrl, imageSize }) => {
  const classes = 'module sovietUnanswered-coverWrapper sovietCover doubleHover is__noUnderline'
  const sizes = imageSize || DEFAULT_IMAGE_SIZE

  return (
    <a href={ sovietUrl } className={ classes }>
      <Image src={ imageUrl } size={ sizes } fillModule loading="lazy" />
    </a>
  )
}

export default SovietUnansweredImage
