const toIframe = (id) => `
<iframe
  src="https://dzen.ru/embed/${id}?from_block=partner&from=zen&mute=0&autoplay=0&tv=0"
  allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
  data-testid="embed-iframe"
  frameborder="0"
  scrolling="no"
  allowfullscreen></iframe>`

class ZenVideo {
  constructor(el) {
    this.el = el
    this.url = el.getAttribute('data-zen-url')
    this.id = el.getAttribute('data-zen-id')
    this.containerEl = document.querySelector(`#zen-${this.id}`)

    this.setup()
  }

  setup() {
    this.containerEl.outerHTML = toIframe(this.id)
  }
}

module.exports = ZenVideo
