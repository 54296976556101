import { Component } from 'preact'

import { Caption } from '../../textNodes'
import NewPaybarDevicesIcons from './newPaybarDevicesIcons'

export default class NewPaybarDevices extends Component {
  get captionText() {
    const { product } = this.props

    return I18n.t(`newPaybar.devices.${ product }`)
  }

  render() {
    return (
      <div className="newPaybarDevices">
        <NewPaybarDevicesIcons className="newPaybarDevices-icon" />
        <div className="newPaybarDevices-text">
          <Caption html={ this.captionText } />
        </div>
      </div>
    )
  }
}
