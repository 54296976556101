import { Component } from 'preact'
import { Cols } from '../cols'
import { Caption, TextNode } from '../textNodes'
import { BookSystemRequirementsWithMobileIcon } from '../icons'

class BookSystemRequirements extends Component {
  get requirementsText() {
    return I18n.t('books.bookSystemRequirements.text')
  }

  render() {
    return (
      <Cols
        className="bookSystemRequirements"
        isRibbed
        mobileRibMask={ [false, true] }
      >
        <div className="bookSystemRequirements-main">
          <div className="bookSystemRequirements-text">
            <TextNode html={ this.requirementsText } />
          </div>
          <div className="bookSystemRequirements-icon">
            <BookSystemRequirementsWithMobileIcon />
          </div>
        </div>
        <div className="bookSystemRequirements-retina">
          <Caption html={ I18n.t('books.bookSystemRequirements.retina') } />
        </div>
      </Cols>
    )
  }
}

export default BookSystemRequirements
