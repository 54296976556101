import cx from 'classnames'
import { LaptopDesktopIcon } from '../../icons'
import NewPaybarPlusPhone from './newPaybarPlusPhone'

export default function NewPaybarDevicesIcons(props) {
  const className = cx('newPaybarDevicesIcons', props.className)

  return (
    <div className={ className }>
      <LaptopDesktopIcon />
      <NewPaybarPlusPhone />
    </div>
  )
}
