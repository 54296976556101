import { Component } from 'preact'
import { connect } from 'preact-redux'

import NewPaybarFieldsetHeading from './newPaybarFieldsetHeading'
import { Caption, TextNode } from '../textNodes'
import { FormInput, FormTextarea } from '../form'
import NewPaybarDocFlowTypeSwitch from './newPaybarDocFlowTypeSwitch'
import { setDocFlowType } from './newPaybarActions'

class NewPaybarPersonalFieldsetCompanySection extends Component {
  render() {
    const isElectronic = this.props.docFlowType === 'electronic'

    return (
      <div class="newPaybarPersonalFieldset-section">
        <NewPaybarFieldsetHeading text={ I18n.t('newPaybar.companyForm.payer') } />

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.companyForm.title') }
          </TextNode>
          <FormInput
            className="newPaybarPersonalFieldset-input"
            name="companyTitle"
            type="text"
            autoComplete="organization"
            required
            onInput={ this.props.onInput }
            onValidityChange={ this.props.onInputValidityChange }
            initialValue={ this.props.rememberedFields.companyTitle }
            ref={ ref => this.props.fieldRefs.companyTitle = ref } />
        </label>

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            <span dangerouslySetInnerHTML={ { __html: I18n.t('newPaybar.companyForm.taxId') } }>
            </span>
          </TextNode>
          <FormInput
            className="newPaybarPersonalFieldset-input"
            name="companyTaxId"
            type="text"
            required
            onInput={ this.props.onInput }
            onValidityChange={ this.props.onInputValidityChange }
            initialValue={ this.props.rememberedFields.companyTaxId }
            ref={ ref => this.props.fieldRefs.companyTaxId = ref } />
        </label>

        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.companyForm.CEO') }
          </TextNode>
          <FormInput
            className="newPaybarPersonalFieldset-input"
            name="companyCEO"
            type="text"
            placeholder={ I18n.t('newPaybar.companyForm.CEOPlaceholder') }
            autoComplete="off"
            required
            onInput={ this.props.onInput }
            onValidityChange={ this.props.onInputValidityChange }
            initialValue={ this.props.rememberedFields.companyCEO }
            ref={ ref => this.props.fieldRefs.companyCEO = ref }
          />
        </label>

        <div className="newPaybarPersonalFieldset-row is__switch">
          <NewPaybarDocFlowTypeSwitch
            docFlowType={ this.props.docFlowType }
            onSetElectronic={ () => this.props.setDocFlowType('electronic') }
            onSetPaper={ () => this.props.setDocFlowType('paper') } />

          <input type="hidden" name="companyDocFlowType" value={ this.props.docFlowType } />
        </div>

        <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.companyForm.email') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="companyEmail"
              type="email"
              autoComplete="off"
              required
              onInput={ this.props.onInput }
              onValidityChange={ this.props.onInputValidityChange }
              initialValue={ this.props.rememberedFields.companyEmail }
              ref={ ref => this.props.fieldRefs.companyEmail = ref } />
        </label>

        { isElectronic &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              <span dangerouslySetInnerHTML={{ __html: I18n.t('newPaybar.companyForm.electronicDocFlowId') }} />
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="companyElectronicDocFlowId"
              type="text"
              required
              onInput={ this.props.onInput }
              onValidityChange={ this.props.onInputValidityChange }
              initialValue={ this.props.rememberedFields.companyElectronicDocFlowId }
              ref={ ref => this.props.fieldRefs.companyElectronicDocFlowId = ref } />
          </label>
        }

        { !isElectronic &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.companyForm.address') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormTextarea
                className="newPaybarPersonalFieldset-input"
                name="companyAddress"
                key="companyAddress"
                type="text"
                required
                minRows={ 3 }
                onInput={ this.props.onInput }
                onValidityChange={ this.props.onInputValidityChange }
                initialValue={ this.props.rememberedFields.companyAddress }
                ref={ ref => this.props.fieldRefs.companyAddress = ref } />

              <Caption html={ I18n.t('newPaybar.companyForm.addressCaption') } />
            </div>
          </label>
        }
      </div>
    )
  }
}

const mapDispatchToProps = { setDocFlowType }

const mapStateToProps = ({ newPaybar }) => {
  return { docFlowType: newPaybar.docFlowType }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPersonalFieldsetCompanySection)
