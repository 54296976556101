import cx from 'classnames'

import NewPaybarLaunchpadItem from '../newPaybarLaunchpadItem'
import BookRibbon from '../../bookRibbon/bookRibbon'

export default class NewPaybarLaunchpadItemBook extends NewPaybarLaunchpadItem {
  get classNames() {
    return cx('newPaybarLaunchpadItemBook', {
      is__cannotRead: this.props.cannotRead,
      is__locked: this.isLocked,
      is__loaded: this.state.isLoaded,
    })
  }

  get href() {
    if (this.props.cannotRead || this.isLocked) return null

    let url = `/books/${this.props.bookKey}/`

    return this.props.hasOnlyDemo ? url + 'demo/' : url
  }

  get src() {
    return `/projects/book-${this.props.bookKey}/files/_book-cover-face.jpg`
  }

  render() {
    return (
      <a href={ this.href } className={ this.classNames }>
        <img src={ this.src } onLoad={ this.setLoaded } ref={ el => this.$image = el }/>

        { this.props.cannotRead && <BookRibbon>{ I18n.t('newPaybar.ribbonNotReady') }</BookRibbon> }
      </a>
    )
  }
}
