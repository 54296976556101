import { Component } from 'preact'
import cx from 'classnames'
import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../../calendar/timezoneUtils'

import { TextNode, Caption } from '../../textNodes'
import Price from '../../price/price'
import Pricing from '../../price/pricing'

const translations = I18n.translations[I18n.locale].newPaybar

export default class NewPaybarPrepPricing extends Component {
  get couponValidUntil() {
    return new Date((this.props.prices.couponValidUntil || 0) * 1000)
  }

  get oneTimePriceLabel() {
    const { isCouponApplied, previousOneTime } = this.props.prices

    if (isCouponApplied) return `<s>${previousOneTime}</s>`

    return '<s>7000</s>'
  }

  get nextPriceLabel() {
    const { willChangeAt, isCouponApplied } = this.props.prices
    const couponEndsAt = new Date(this.couponValidUntil.getTime() + 1000)
    const priceWillChangeAt = isCouponApplied ? couponEndsAt : willChangeAt

    return birmanize(priceWillChangeAt, { format: 'sinceWeekDayLowerCaseAndDate' })
  }

  get pricingInfo() {
    if (!translations.pricingInfo.prep) return null
    if (this.props.skipPricingInfo) return null

    const { isCouponApplied } = this.props.prices
    const couponEndDate = birmanize(moscowifyLocal(this.couponValidUntil), { format: 'dateAndTime' })

    if (isCouponApplied) return I18n.t('newPaybar.pricingInfo.prep.coupon', { couponEndDate })

    return I18n.t(`newPaybar.pricingInfo.prep.${this.props.type}`)
  }

  render() {
    const {
      isCouponApplied,
      oneTime,
      previousOneTime,
      nextPrice,
      endsAt,
    } = this.props.prices
    const isCouponEndsBeforePriceChange = this.couponValidUntil < new Date(endsAt)
    const priceAfterCoupon = isCouponEndsBeforePriceChange ? previousOneTime : nextPrice

    return (
      <div className={ cx('newPaybarPricing', { is__couponApplied: isCouponApplied }) }>
          <Pricing>
            <Price
              label={ this.oneTimePriceLabel }
              amount={ oneTime }
              units="₽"
              highlighted
              smallUnits />

            <Price
              label={ this.nextPriceLabel }
              amount={ isCouponApplied ? priceAfterCoupon : nextPrice }
              units="₽"
              smallUnits />
          </Pricing>

          { this.pricingInfo &&
            <TextNode
              className="newPaybarPricing-info module device device__mobile"
              html={ this.pricingInfo } />
          }

          { this.pricingInfo &&
            <Caption
              className="newPaybarPricing-info module device device__desktop device__laptop"
              html={ this.pricingInfo } />
          }
      </div>
    )
  }
}
